import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { BaseUrl } from '../url';

interface Item {
	_id: string;
	name: string;
	source: string;
	deletedAt: string;
}

interface DeletedItemsState {
	deleteditems: Item[];
	filteredItems: Item[];
	loading: boolean;
	error: string | null;
	restore: boolean;
	filterSource: string | null;
}

const initialState: DeletedItemsState = {
	deleteditems: [],
	filteredItems: [],
	loading: false,
	error: null,
	restore: false,
	filterSource: null,
};

const sourceMapping = [
	'User',
	'Category',
	'Course',
	'CourseModule',
	'Participants',
	'Enrolled',
	'Carts',
	'Orders',
	'BlogCategory',
	'Blog',
	'Feedback',
];

export const fetchDeletedItems = createAsyncThunk<Item[], { pageNo: number; pageSize: number }>(
	'deletedItems/fetchDeletedItems',
	async ({ pageNo, pageSize }) => {
		const responses = await Promise.all(
			sourceMapping.map((source) =>
				axios
					.get(`${BaseUrl}${source}/getalldeleted`, {
						params: {
							page_no: pageNo,
							page_size: pageSize,
						},
					})
					.then((response: any) => ({
						data: response.data.data,
						source,
					})),
			),
		);

		const combinedResults: Item[] = [];
		responses.forEach(({ data, source }) => {
			data.forEach((item: any) => {
				combinedResults.push({ ...item, source });
			});
		});

		combinedResults.sort((a, b) => new Date(b.deletedAt).getTime() - new Date(a.deletedAt).getTime());

		return combinedResults;
	},
);

export const restoreItem = createAsyncThunk<Item, { id: string; source: string }>(
	'deletedItems/restoreItem',
	async ({ id, source }) => {
		const response = await axios.put<{ data: Item }>(
			`${BaseUrl}${source}/updateIsDeleted/${id}`,
			{
				is_deleted: false,
			},
		);
		return response.data.data;
	},
);

const deletedItemsSlice = createSlice({
	name: 'deletedItems',
	initialState,
	reducers: {
		setFilterSource(state, action: PayloadAction<string | null>) {
			state.filterSource = action.payload;
			if (state.filterSource) {
				state.filteredItems = state.deleteditems.filter(item => item.source === state.filterSource);
			} else {
				state.filteredItems = state.deleteditems;
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchDeletedItems.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchDeletedItems.fulfilled, (state, action: PayloadAction<Item[]>) => {
				state.loading = false;
				state.deleteditems = action.payload;
				state.filteredItems = state.filterSource
					? state.deleteditems.filter(item => item.source === state.filterSource)
					: state.deleteditems;
			})
			.addCase(fetchDeletedItems.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message || null;
			})
			.addCase(restoreItem.fulfilled, (state, action: PayloadAction<Item>) => {
				state.deleteditems = state.deleteditems.filter(
					(item) => item._id !== action.payload._id,
				);
				state.filteredItems = state.filterSource
					? state.deleteditems.filter(item => item.source === state.filterSource)
					: state.deleteditems;
			});
	},
});

export const { setFilterSource } = deletedItemsSlice.actions;

export default deletedItemsSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
	createBlog,
	getAllBlog,
	getAllByblogCategory,
	getByIdBlog,
	removeBlog,
	updateBlog,
} from '../../api/blog/blogByCategory ';

interface UserState {
	blog: any;
	blogs: any[];
	loading: boolean;
	error: any;
}

interface RemoveModalState {
	isOpen: boolean;
	idToRemove: any;
}

const initialRemoveModalState: RemoveModalState = {
	isOpen: false,
	idToRemove: null,
};

const initialState: UserState = {
	blog: null,
	blogs: [],
	loading: false,
	error: null,
};

// Generalized async thunk function
const AsyncFunctionThunk = (name: string, apiFunction: any) => {
	return createAsyncThunk<any, any>(`blog/${name}`, async (data: any, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<any, any> = await apiFunction(data);
			return response.data;
		} catch (error: any) {
			console.error(`Failed to ${name}:`, error);
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue({ error: error.message });
		}
	});
};

// Define async thunks for each operation
export const createBlogSlice = AsyncFunctionThunk('createBlog', createBlog);
export const getAllBlogSlice = AsyncFunctionThunk('getAllBlog', getAllBlog);
export const removeBlogSlice = AsyncFunctionThunk('removeBlog', removeBlog);
export const updateBlogSlice = AsyncFunctionThunk('updateBlog', updateBlog);
export const getByIdBlogslice = AsyncFunctionThunk('getByIdBlog', getByIdBlog);
export const getAllByblogCategorySlice = AsyncFunctionThunk(
	'getAllByblogCategory',
	getAllByblogCategory,
);

// Create the slice for remove modal
const removeModalSlice = createSlice({
	name: 'removeModal',
	initialState: initialRemoveModalState,
	reducers: {
		openRemoveModal: (state, action) => {
			state.isOpen = true;
			state.idToRemove = action.payload;
		},
		closeRemoveModal: (state) => {
			state.isOpen = false;
			state.idToRemove = null;
		},
	},
});

// Combine both slices
export const { openRemoveModal, closeRemoveModal } = removeModalSlice.actions;

// Create the slice for course modules
const BlogSlice = createSlice({
	name: 'Blogslice',
	initialState,
	reducers: {
		setError: (state, action) => {
			state.error = null;
		},
		clearBlogSlice: (state) => {
			state.blog = null;
		},
	},
	extraReducers: (builder) => {
		builder
			// createBlogSlice
			.addCase(createBlogSlice.fulfilled, (state, action) => {
				state.blogs = action.payload;
				state.loading = false;
			})
			.addCase(createBlogSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(createBlogSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// getAllByblogCategorySlice
			.addCase(getAllByblogCategorySlice.fulfilled, (state, action) => {
				state.blogs = action.payload;
				state.loading = false;
			})
			.addCase(getAllByblogCategorySlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllByblogCategorySlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// getAllBlogSlice
			.addCase(getAllBlogSlice.fulfilled, (state, action) => {
				state.blogs = action.payload;
				state.loading = false;
			})
			.addCase(getAllBlogSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllBlogSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// removeBlogslice
			.addCase(removeBlogSlice.fulfilled, (state, action) => {
				state.blogs = action.payload;
				state.loading = false;
			})
			.addCase(removeBlogSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(removeBlogSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// updateBlogSlice
			.addCase(updateBlogSlice.fulfilled, (state, action) => {
				state.blogs = action.payload;
				state.loading = false;
			})
			.addCase(updateBlogSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateBlogSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// getByIdBlogslice
			.addCase(getByIdBlogslice.fulfilled, (state, action) => {
				state.blog = action.payload;
				state.loading = false;
			})
			.addCase(getByIdBlogslice.pending, (state) => {
				state.loading = true;
			})
			.addCase(getByIdBlogslice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

// Export actions and reducers
export const { setError, clearBlogSlice } = BlogSlice.actions;
export default BlogSlice.reducer;

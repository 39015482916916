import axios from 'axios';
import { BaseUrl } from '../../url';

export const createBlogCategory = async (data: any) => {
	try {
		const resoponse = await axios.post(`${BaseUrl}BlogCategory/create`, data);
		return resoponse;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const getAllBlogCategory = async (pageSize: any) => {
	const { pageNo, page } = pageSize;
	try {
		const resoponse = await axios.get(
			`${BaseUrl}BlogCategory/getall?page_no=${pageNo}&page_size=${page}`,
		);
		return resoponse;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const removeBlogCategory = async (removeId: any) => {
	try {
		const resoponse = await axios.delete(`${BaseUrl}BlogCategory/remove/${removeId}`);
		return resoponse;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const updateBlogCategory = async (data: any) => {
	const { id, values } = data;
	try {
		const resoponse = await axios.put(`${BaseUrl}BlogCategory/update/${id}`, values);
		return resoponse;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const getByIdBlogCategory = async (id: any) => {
	try {
		const resoponse = await axios.get(`${BaseUrl}BlogCategory/getById/${id}`);
		return resoponse;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const updatethumbBlogCategory = async (data: any) => {
	const { image, id, config } = data;

	const formData = new FormData();
	formData.append('image', image);

	try {
		const response = await axios.post(`${BaseUrl}BlogCategory/uploadImage/${id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			onUploadProgress: config,
		});
		return response;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

import axios from 'axios';
import { BaseUrl } from '../../url';

export const createOrders = async (data: any) => {
	try {
		const resoponse = await axios.post(`${BaseUrl}Orders/create`, data);
		return resoponse;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

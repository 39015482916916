import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
	createCategory,
	getAllCategory,
	getByIdCategory,
	removeCategory,
	updateCategory,
	updatethumbCategory,
} from '../api/category';

interface UserState {
	category: any;
	categories: any[];
	loading: boolean;
	error: any;
}

interface RemoveModalState {
	isOpen: boolean;
	idToRemove: any;
}

const initialRemoveModalState: RemoveModalState = {
	isOpen: false,
	idToRemove: null,
};

const initialState: UserState = {
	category: null,
	categories: [],
	loading: false,
	error: null,
};

// Generalized async thunk function
const AsyncFunctionThunk = (name: string, apiFunction: any) => {
	return createAsyncThunk<any, any>(
		`category/${name}`,
		async (data: any, { rejectWithValue }) => {
			try {
				const response: AxiosResponse<any, any> = await apiFunction(data);
				return response.data;
			} catch (error: any) {
				console.error(`Failed to ${name}:`, error);
				if (error.response && error.response.data) {
					return rejectWithValue(error.response.data);
				}
				return rejectWithValue({ error: error.message });
			}
		},
	);
};

// Define async thunks for each operation
export const createCategorySlice = AsyncFunctionThunk('createCategory', createCategory);
export const getAllCategorySlice = AsyncFunctionThunk('getAllCategory', getAllCategory);
export const removeCategorySlice = AsyncFunctionThunk('removeCategory', removeCategory);
export const updateCategorySlice = AsyncFunctionThunk('updateCategory', updateCategory);
export const getByIdcategoryslice = AsyncFunctionThunk('getByIdCategory', getByIdCategory);
export const uploadThumbcategoryslice = AsyncFunctionThunk(
	'updatethumbCategory',
	updatethumbCategory,
);

// Create the slice for remove modal
const removeModalSlice = createSlice({
	name: 'removeModal',
	initialState: initialRemoveModalState,
	reducers: {
		openRemoveModal: (state, action) => {
			state.isOpen = true;
			state.idToRemove = action.payload;
		},
		closeRemoveModal: (state) => {
			state.isOpen = false;
			state.idToRemove = null;
		},
	},
});

// Combine both slices
export const { openRemoveModal, closeRemoveModal } = removeModalSlice.actions;

// Create the slice for course modules
const CategorieSlice = createSlice({
	name: 'categorieSlice',
	initialState,
	reducers: {
		setError: (state, action) => {
			state.error = null;
		},
	},
	extraReducers: (builder) => {
		builder
			// createCategorySlice
			.addCase(createCategorySlice.fulfilled, (state, action) => {
				state.categories = action.payload;
				state.loading = false;
			})
			.addCase(createCategorySlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(createCategorySlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// getAllCategorySlice
			.addCase(getAllCategorySlice.fulfilled, (state, action) => {
				state.categories = action.payload;
				state.loading = false;
			})
			.addCase(getAllCategorySlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllCategorySlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// removecategoryslice
			.addCase(removeCategorySlice.fulfilled, (state, action) => {
				state.categories = action.payload;
				state.loading = false;
			})
			.addCase(removeCategorySlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(removeCategorySlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// updateCategorySlice
			.addCase(updateCategorySlice.fulfilled, (state, action) => {
				state.categories = action.payload;
				state.loading = false;
			})
			.addCase(updateCategorySlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateCategorySlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// getByIdcategoryslice
			.addCase(getByIdcategoryslice.fulfilled, (state, action) => {
				state.category = action.payload;
				state.loading = false;
			})
			.addCase(getByIdcategoryslice.pending, (state) => {
				state.loading = true;
			})
			.addCase(getByIdcategoryslice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// uploadThumbcategoryslice
			.addCase(uploadThumbcategoryslice.fulfilled, (state, action) => {
				const index = state.categories.findIndex(
					(module) => module._id === action.payload._id,
				);
				if (index !== -1) {
					state.categories[index] = {
						...state.categories[index],
						image: action.payload.image,
					};
				}
				state.loading = false;
			})
			.addCase(uploadThumbcategoryslice.pending, (state) => {
				state.loading = true;
			})
			.addCase(uploadThumbcategoryslice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

// Export actions and reducers
export const { setError } = CategorieSlice.actions;
export default CategorieSlice.reducer;

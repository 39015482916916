import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
	createCourse,
	getAllCourse,
	getByIdCourse,
	removeCourse,
	updateCourse,
	updatethumbCourse,
} from '../api/course';

interface UserState {
	course: any;
	courses: any[];
	loading: boolean;
	error: any;
}

interface RemoveModalState {
	isOpen: boolean;
	idToRemove: any;
}

const initialRemoveModalState: RemoveModalState = {
	isOpen: false,
	idToRemove: null,
};

const initialState: UserState = {
	course: null,
	courses: [],
	loading: false,
	error: null,
};

// Generalized async thunk function
const AsyncFunctionThunk = (name: string, apiFunction: any) => {
	return createAsyncThunk<any, any>(
		`Course/${name}`,
		async (data: any, { rejectWithValue }) => {
			try {
				const response: AxiosResponse<any, any> = await apiFunction(data);
				return response.data;
			} catch (error: any) {
				console.error(`Failed to ${name}:`, error);
				if (error.response && error.response.data) {
					return rejectWithValue(error.response.data);
				}
				return rejectWithValue({ error: error.message });
			}
		},
	);
};

// Define async thunks for each operation
export const createCourseSlice = AsyncFunctionThunk('createCourse', createCourse);
export const getAllCourseSlice = AsyncFunctionThunk('getAllCourse', getAllCourse);
export const removeCourseSlice = AsyncFunctionThunk('removeCourse', removeCourse);
export const updateCourseSlice = AsyncFunctionThunk('updateCourse', updateCourse);
export const getByIdCourseslice = AsyncFunctionThunk('getByIdCourse', getByIdCourse);
export const uploadThumbCourseslice = AsyncFunctionThunk(
	'updatethumbCourse',
	updatethumbCourse,
);

// Create the slice for remove modal
const removeModalSlice = createSlice({
	name: 'removeModal',
	initialState: initialRemoveModalState,
	reducers: {
		openRemoveModal: (state, action) => {
			state.isOpen = true;
			state.idToRemove = action.payload;
		},
		closeRemoveModal: (state) => {
			state.isOpen = false;
			state.idToRemove = null;
		},
	},
});

// Combine both slices
export const { openRemoveModal, closeRemoveModal } = removeModalSlice.actions;

// Create the slice for course modules
const CourseSlice = createSlice({
	name: 'CourseSlice',
	initialState,
	reducers: {
		setError: (state, action) => {
			state.error = null;
		},
	},
	extraReducers: (builder) => {
		builder
			// createCourseSlice
			.addCase(createCourseSlice.fulfilled, (state, action) => {
				state.courses = action.payload;
				state.loading = false;
			})
			.addCase(createCourseSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(createCourseSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// getAllCourseSlice
			.addCase(getAllCourseSlice.fulfilled, (state, action) => {
				state.courses = action.payload;
				state.loading = false;
			})
			.addCase(getAllCourseSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllCourseSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// removeCourseslice
			.addCase(removeCourseSlice.fulfilled, (state, action) => {
				state.courses = action.payload;
				state.loading = false;
			})
			.addCase(removeCourseSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(removeCourseSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// updateCourseSlice
			.addCase(updateCourseSlice.fulfilled, (state, action) => {
				state.courses = action.payload;
				state.loading = false;
			})
			.addCase(updateCourseSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateCourseSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// getByIdCourseslice
			.addCase(getByIdCourseslice.fulfilled, (state, action) => {
				state.course = action.payload;
				state.loading = false;
			})
			.addCase(getByIdCourseslice.pending, (state) => {
				state.loading = true;
			})
			.addCase(getByIdCourseslice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// uploadThumbCourseslice
			.addCase(uploadThumbCourseslice.fulfilled, (state, action) => {
				const index = state.courses.findIndex(
					(module) => module._id === action.payload._id,
				);
				if (index !== -1) {
					state.courses[index] = {
						...state.courses[index],
						image: action.payload.image,
					};
				}
				state.loading = false;
			})
			.addCase(uploadThumbCourseslice.pending, (state) => {
				state.loading = true;
			})
			.addCase(uploadThumbCourseslice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

// Export actions and reducers
export const { setError } = CourseSlice.actions;
export default CourseSlice.reducer;

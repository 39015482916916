import { configureStore, combineReducers } from '@reduxjs/toolkit';

import AuthSlice from './slice/auth';
import SubModuleSlice from './slice/subModules';
import UserSlice from './slice/users';
import CategorieSlice from './slice/category';
import CourseSlice from './slice/course';
import CourseModuleSlice from './slice/courseModules';
import FeedbackSlice from './slice/feedBack';
import EnrolmentSlice from './slice/enrolment';
import RoleSlice from './slice/accessControl';
import uploadProgressReducer from './slice/upload';
import BlogBlogCategorieReducer from './slice/blog/blogCategory';
import BlogSlice from './slice/blog/blogByCategory';
import deletedItemsReducer from './slice/bin';
import OrderSlice from './slice/payment/Orders';

const rootReducer = combineReducers({
	role: RoleSlice,
	auth: AuthSlice,
	category: CategorieSlice,
	course: CourseSlice,
	courseModule: CourseModuleSlice,
	subModule: SubModuleSlice,
	users: UserSlice,
	feedback: FeedbackSlice,
	enrolment: EnrolmentSlice,
	uploadings: uploadProgressReducer,
	blogCategory: BlogBlogCategorieReducer,
	blog: BlogSlice,
	bin: deletedItemsReducer,
	order: OrderSlice,
});

export const store = configureStore({
	reducer: rootReducer,
});

// import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import AuthSlice from './slice/auth';
// import CourseModuleSlice from './slice/modules';
// import UserSlice from './slice/users';
// import CategorieSlice from './slice/category';
// import CourseSlice from './slice/course';
// import FeedbackSlice from './slice/feedBack';
// import EnrolmentSlice from './slice/enrolment';
// import RoleSlice from './slice/accessControl';
// import uploadProgressReducer from './slice/upload';
// import BlogBlogCategorieReducer from './slice/blog/blogCategory';
// import BlogSlice from './slice/blog/blogByCategory';
// import deletedItemsReducer from './slice/bin';

// const rootReducer = combineReducers({
// 	role: RoleSlice,
// 	auth: AuthSlice,
// 	courseModule: CourseModuleSlice,
// 	users: UserSlice,
// 	category: CategorieSlice,
// 	course: CourseSlice,
// 	feedback: FeedbackSlice,
// 	enrolment: EnrolmentSlice,
// 	uploadings: uploadProgressReducer,
// 	blogCategory: BlogBlogCategorieReducer,
// 	blog: BlogSlice,
// 	bin: deletedItemsReducer,
// });

// export const store = configureStore({
// 	reducer: rootReducer,
// });

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;

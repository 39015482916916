import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { createSubModule, getAllSubModule, getByIdSubModule, getSubModuleByModuleId, getSubModulesByCategoryApi, removeSubModule, updateSubModule, updatethumbSubModule } from '../api/subModules';


interface UserState {
	subModule: any;
	subModules: any[];
	loading: boolean;
	error: any;
}

interface RemoveModalState {
	isOpen: boolean;
	idToRemove: any;
}

const initialRemoveModalState: RemoveModalState = {
	isOpen: false,
	idToRemove: null,
};

const initialState: UserState = {
	subModule: null,
	subModules: [],
	loading: false,
	error: null,
};

// Generalized async thunk function
const AsyncFunctionThunk = (name: string, apiFunction: any) => {
	return createAsyncThunk<any, any>(
		`module/${name}`,
		async (data: any, { rejectWithValue }) => {
			try {
				const response: AxiosResponse<any, any> = await apiFunction(data);
				return response.data;
			} catch (error: any) {
				console.error(`Failed to ${name}:`, error);
				if (error.response && error.response.data) {
					return rejectWithValue(error.response.data);
				}
				return rejectWithValue({ error: error.message });
			}
		},
	);
};

// Define async thunks for each operation
export const createSubModuleSlice = AsyncFunctionThunk('createsubModule', createSubModule);
export const getAllSubModuleSlice = AsyncFunctionThunk('getAllSubModule', getAllSubModule);
export const removeSubModuleSlice = AsyncFunctionThunk('removeSubModule', removeSubModule);
export const updateSubModuleSlice = AsyncFunctionThunk('updateSubModule', updateSubModule);
export const getByIdSubModuleSlice = AsyncFunctionThunk(
	'getByIdSubModule',
	getByIdSubModule,
);
export const getSubModulesByCategorySlice = AsyncFunctionThunk(
	'getSubModulesByCategoryApi',
	getSubModulesByCategoryApi,
);
export const uploadThumbmoduleSlice = AsyncFunctionThunk(
	'updatethumbSubModule',
	updatethumbSubModule,
);
export const getSubModuleByModuleIdSlice = AsyncFunctionThunk(
	'getSubModuleByModuleId',
	getSubModuleByModuleId,
);

// Create the slice for remove modal
const removeModalSlice = createSlice({
	name: 'removeModal',
	initialState: initialRemoveModalState,
	reducers: {
		openRemoveModal: (state, action) => {
			state.isOpen = true;
			state.idToRemove = action.payload;
		},
		closeRemoveModal: (state) => {
			state.isOpen = false;
			state.idToRemove = null;
		},
	},
});

// Combine both slices
export const { openRemoveModal, closeRemoveModal } = removeModalSlice.actions;

// Create the slice for course modules
const SubModuleSlice = createSlice({
	name: 'SubModuleSlice',
	initialState,
	reducers: {
		setError: (state, action) => {
			state.error = null;
		},
	},
	extraReducers: (builder) => {
		builder
			// createSubModuleSlice
			.addCase(createSubModuleSlice.fulfilled, (state, action) => {
				state.subModules = action.payload.data;
				state.loading = false;
			})
			.addCase(createSubModuleSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// getAllSubModuleSlice
			.addCase(getAllSubModuleSlice.fulfilled, (state, action) => {
				state.subModules = action.payload.data;
				state.loading = false;
			})
			.addCase(getAllSubModuleSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllSubModuleSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// removeSubModuleSlice
			.addCase(removeSubModuleSlice.fulfilled, (state, action) => {
				state.subModules = action.payload;
				state.loading = false;
			})
			.addCase(removeSubModuleSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(removeSubModuleSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// updateSubModuleSlice
			.addCase(updateSubModuleSlice.fulfilled, (state, action) => {
				state.subModules = action.payload.data;
				state.loading = false;
			})
			.addCase(updateSubModuleSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
				// getSubModuleByModuleIdSlice
				.addCase(getSubModuleByModuleIdSlice.fulfilled, (state, action) => {
					console.log('action.payload', action.payload)
					state.subModules = action.payload.data;
					state.loading = false;
				})
				.addCase(getSubModuleByModuleIdSlice.pending, (state) => {
					state.loading = true;
				})
				.addCase(getSubModuleByModuleIdSlice.rejected, (state, action) => {
					state.loading = false;
					state.error = action.payload;
				})
			// getByIdSubModuleSlice
			.addCase(getByIdSubModuleSlice.fulfilled, (state, action) => {
				state.subModule = action.payload.data;
				state.loading = false;
			})
			.addCase(getByIdSubModuleSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(getByIdSubModuleSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// getSubModulesByCategorySlice
			.addCase(getSubModulesByCategorySlice.fulfilled, (state, action) => {
				console.log('action.payload', action.payload)
				state.subModules = action.payload;
				state.loading = false;
			})
			.addCase(getSubModulesByCategorySlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(getSubModulesByCategorySlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// uploadThumbmoduleSlice
			.addCase(uploadThumbmoduleSlice.fulfilled, (state, action) => {
				const index = state.subModules.findIndex(
					(module) => module._id === action.payload._id,
				);
				if (index !== -1) {
					state.subModules[index] = {
						...state.subModules[index],
						image: action.payload.image,
					};
				}
				state.loading = false;
			})
			.addCase(uploadThumbmoduleSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(uploadThumbmoduleSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

// Export actions and reducers
export const { setError } = SubModuleSlice.actions;
export default SubModuleSlice.reducer;

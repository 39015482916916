import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
	createBlogCategory,
	getAllBlogCategory,
	getByIdBlogCategory,
	removeBlogCategory,
	updateBlogCategory,
} from '../../api/blog/blogCategory';

interface UserState {
	blogCategory: any;
	blogCategories: any[];
	loading: boolean;
	error: any;
}

interface RemoveModalState {
	isOpen: boolean;
	idToRemove: any;
}

const initialRemoveModalState: RemoveModalState = {
	isOpen: false,
	idToRemove: null,
};

const initialState: UserState = {
	blogCategory: null,
	blogCategories: [],
	loading: false,
	error: null,
};

// Generalized async thunk function
const AsyncFunctionThunk = (name: string, apiFunction: any) => {
	return createAsyncThunk<any, any>(
		`blogCategory/${name}`,
		async (data: any, { rejectWithValue }) => {
			try {
				const response: AxiosResponse<any, any> = await apiFunction(data);
				return response.data;
			} catch (error: any) {
				console.error(`Failed to ${name}:`, error);
				if (error.response && error.response.data) {
					return rejectWithValue(error.response.data);
				}
				return rejectWithValue({ error: error.message });
			}
		},
	);
};

// Define async thunks for each operation
export const createBlogCategorieSlice = AsyncFunctionThunk(
	'createBlogCategory',
	createBlogCategory,
);
export const getAllBlogCategorieSlice = AsyncFunctionThunk(
	'getAllBlogCategory',
	getAllBlogCategory,
);
export const removeBlogCategorieSlice = AsyncFunctionThunk(
	'removeBlogCategory',
	removeBlogCategory,
);
export const updateBlogCategorieSlice = AsyncFunctionThunk(
	'updateBlogCategory',
	updateBlogCategory,
);
export const getByIdBlogcategorieslice = AsyncFunctionThunk(
	'getByIdBlogCategory',
	getByIdBlogCategory,
);

// Create the slice for remove modal
const removeModalSlice = createSlice({
	name: 'removeModal',
	initialState: initialRemoveModalState,
	reducers: {
		openRemoveModal: (state, action) => {
			state.isOpen = true;
			state.idToRemove = action.payload;
		},
		closeRemoveModal: (state) => {
			state.isOpen = false;
			state.idToRemove = null;
		},
	},
});

// Combine both slices
export const { openRemoveModal, closeRemoveModal } = removeModalSlice.actions;

// Create the slice for course modules
const BlogBlogCategorieSlice = createSlice({
	name: 'BlogcategorieSlice',
	initialState,
	reducers: {
		setError: (state, action) => {
			state.error = null;
		},
		clearBlogCategorySlice: (state) => {
			state.blogCategory = null;
		},
	},
	extraReducers: (builder) => {
		builder
			// createBlogCategorieSlice
			.addCase(createBlogCategorieSlice.fulfilled, (state, action) => {
				state.blogCategories = action.payload;
				state.loading = false;
			})
			.addCase(createBlogCategorieSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(createBlogCategorieSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// getAllBlogCategorieSlice
			.addCase(getAllBlogCategorieSlice.fulfilled, (state, action) => {
				state.blogCategories = action.payload;
				state.loading = false;
			})
			.addCase(getAllBlogCategorieSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(getAllBlogCategorieSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// removeBlogcategorieslice
			.addCase(removeBlogCategorieSlice.fulfilled, (state, action) => {
				state.blogCategories = action.payload;
				state.loading = false;
			})
			.addCase(removeBlogCategorieSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(removeBlogCategorieSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// updateBlogCategorieSlice
			.addCase(updateBlogCategorieSlice.fulfilled, (state, action) => {
				state.blogCategories = action.payload;
				state.loading = false;
			})
			.addCase(updateBlogCategorieSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(updateBlogCategorieSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			// getByIdBlogcategorieslice
			.addCase(getByIdBlogcategorieslice.fulfilled, (state, action) => {
				state.blogCategory = action.payload;
				state.loading = false;
			})
			.addCase(getByIdBlogcategorieslice.pending, (state) => {
				state.loading = true;
			})
			.addCase(getByIdBlogcategorieslice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

// Export actions and reducers
export const { setError, clearBlogCategorySlice } = BlogBlogCategorieSlice.actions;
export default BlogBlogCategorieSlice.reducer;

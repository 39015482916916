import React from 'react';
import { RouteProps } from 'react-router-dom';
import {
	componentPagesMenu,
	dashboardPagesMenu,
	demoPagesMenu,
	gettingStartedPagesMenu,
	pageLayoutTypesPagesMenu,
} from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DashboardBookingHeader from '../pages/_layout/_headers/DashboardBookingHeader';
import ProfilePageHeader from '../pages/_layout/_headers/ProfilePageHeader';
import SummaryHeader from '../pages/_layout/_headers/SummaryHeader';
import ProductsHeader from '../pages/_layout/_headers/ProductsHeader';
import ProductListHeader from '../pages/_layout/_headers/ProductListHeader';
import PageLayoutHeader from '../pages/_layout/_headers/PageLayoutHeader';
import ComponentsHeader from '../pages/_layout/_headers/ComponentsHeader';
import FormHeader from '../pages/_layout/_headers/FormHeader';
import ChartsHeader from '../pages/_layout/_headers/ChartsHeader';
import ContentHeader from '../pages/_layout/_headers/ContentHeader';
import UtilitiesHeader from '../pages/_layout/_headers/UtilitiesHeader';
import IconHeader from '../pages/_layout/_headers/IconHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';
import DocumentationHeader from '../pages/_layout/_headers/DocumentationHeader';
import { Menu } from '../components/icon/material-icons';
import ElearningDefaultHeader from '../pages/_layout/_headers/e-learningDefaultHeader';
import AccessControllHeader from '../pages/_layout/_headers/AccessControllHeader';
import DashboardHeaderEL from '../pages/presentation/e-learning/dashboard/header/DashboardHeader';
// import DashboardHeaderEL from '../pages/presentation/dashboard/header/HeaderDashboard';

const headers: RouteProps[] = [
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path, element: null },
	{ path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path, element: null },
	{ path: pageLayoutTypesPagesMenu.blank.path, element: null },
	{ path: demoPagesMenu.login.path, element: null },
	{ path: demoPagesMenu.signUp.path, element: null },
	{ path: demoPagesMenu.page404.path, element: null },
	{ path: demoPagesMenu.knowledge.subMenu.grid.path, element: null },
	// { path: dashboardPagesMenu.dashboard.path, element: <DashboardHeader /> },
	// {
	// 	path: 'null',
	// 	// path: demoPagesMenu.projectManagement.subMenu.list.path,
	// 	element: <DashboardHeader />,
	// },
	// path: 'null',
	// { path: demoPagesMenu.pricingTable.path, element: <DashboardHeader /> },
	// {
	// 	path: dashboardPagesMenu.dashboardBooking.path,
	// 	element: <DashboardBookingHeader />,
	// },
	{
		path: 'null',
		// path: demoPagesMenu.appointment.subMenu.calendar.path,
		element: <DashboardBookingHeader />,
	},
	{
		path: 'null',
		// path: demoPagesMenu.appointment.subMenu.employeeList.path,
		element: <DashboardBookingHeader />,
	},
	{
		path: 'null',
		// path: demoPagesMenu.listPages.subMenu.listFluid.path,
		element: <DashboardBookingHeader />,
	},
	{
		path: `${demoPagesMenu.editPages.path}/*`,
		element: <DashboardBookingHeader />,
	},
	{
		path: `${demoPagesMenu.appointment.subMenu.employeeID.path}/*`,
		element: <DashboardBookingHeader />,
	},
	{
		path: `${demoPagesMenu.projectManagement.subMenu.itemID.path}/*`,
		element: <DashboardBookingHeader />,
	},
	{
		path: 'null',
		// path: demoPagesMenu.singlePages.subMenu.fluidSingle.path,
		element: <ProfilePageHeader />,
	},
	{
		path: 'null',
		// path: demoPagesMenu.singlePages.subMenu.boxedSingle.path,
		element: <ProfilePageHeader />,
	},
	{
		path: 'null',
		// path: demoPagesMenu.sales.subMenu.transactions.path,
		element: <ProfilePageHeader />,
	},
	{
		path: 'null',
		// path: demoPagesMenu.chat.subMenu.withListChat.path,
		element: <ProfilePageHeader />,
	},
	{
		path: 'null',
		// path: demoPagesMenu.chat.subMenu.onlyListChat.path,
		element: <ProfilePageHeader />,
	},
	{
		path: `${demoPagesMenu.knowledge.subMenu.itemID.path}/:id`,
		element: <ProfilePageHeader />,
	},
	{
		path: 'null',
		// path: demoPagesMenu.crm.subMenu.dashboard.path,
		element: <ProfilePageHeader />,
	},
	{
		path: 'null',
		// path: demoPagesMenu.crm.subMenu.customersList.path,
		element: <ProfilePageHeader />,
	},
	{
		path: `${demoPagesMenu.crm.subMenu.customerID.path}/:id`,
		element: <ProfilePageHeader />,
	},
	// {
	// 	path: dashboardPagesMenu.summary.path,
	// 	element: <SummaryHeader />,
	// },
	{
		path: 'null',
		// path: demoPagesMenu.gridPages.subMenu.gridBoxed.path,
		element: <ProductsHeader />,
	},
	{
		path: 'null',
		// path: demoPagesMenu.gridPages.subMenu.gridFluid.path,
		element: <ProductsHeader />,
	},
	{
		path: 'null',
		// path: demoPagesMenu.listPages.subMenu.listBoxed.path,
		element: <ProductListHeader />,
	},
	{
		path: 'null',
		// path: demoPagesMenu.sales.subMenu.salesList.path,
		element: <ProductListHeader />,
	},
	{
		path: 'null',
		// path: demoPagesMenu.sales.subMenu.productsGrid.path,
		element: <ProductListHeader />,
	},
	{
		path: 'null',
		// path: `${demoPagesMenu.sales.subMenu.productID.path}/:id`,
		element: <ProductListHeader />,
	},
	{
		path: 'null',
		// path: `${pageLayoutTypesPagesMenu.asideTypes.path}/*`,
		element: <PageLayoutHeader />,
	},
	{
		path: 'null',
		// path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PageLayoutHeader />,
	},
	{
		path: 'null',
		// path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PageLayoutHeader />,
	},
	{
		path: 'null',
		// path: `${componentPagesMenu.components.path}/*`,
		element: <ComponentsHeader />,
	},
	{
		path: 'null',
		// path: `${componentPagesMenu.forms.path}/*`,
		element: <FormHeader />,
	},
	{
		path: 'null',
		// path: `${componentPagesMenu.charts.path}/*`,
		element: <ChartsHeader />,
	},
	{
		path: 'null',
		// path: `${componentPagesMenu.content.path}/*`,
		element: <ContentHeader />,
	},
	{
		path: 'null',
		// path: `${componentPagesMenu.utilities.path}/*`,
		element: <UtilitiesHeader />,
	},
	{
		path: 'null',
		// path: `${componentPagesMenu.icons.path}/*`,
		element: <IconHeader />,
	},
	{
		path: 'null',
		// path: `${gettingStartedPagesMenu.gettingStarted.path}/*`,
		element: <DocumentationHeader />,
	},
	{
		path: `${dashboardPagesMenu.accessControl.path}/*`,
		element: <AccessControllHeader />,
	},
	{
		path: `${dashboardPagesMenu.curriculumManagement.path}/*`,
		// element: <DashboardHeaderEL />,
		element: null,
	},
	{
		path: `*`,
		element: null,
	},
];

export default headers;

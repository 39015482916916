import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { createOrders } from '../../api/payment/order';

interface UserState {
	order: any;
	orders: any[];
	loading: boolean;
	error: any;
}

const initialState: UserState = {
	order: null,
	orders: [],
	loading: false,
	error: null,
};

// Generalized async thunk function
const AsyncFunctionThunk = (name: string, apiFunction: any) => {
	return createAsyncThunk<any, any>(`blog/${name}`, async (data: any, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<any, any> = await apiFunction(data);
			return response.data;
		} catch (error: any) {
			console.error(`Failed to ${name}:`, error);
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue({ error: error.message });
		}
	});
};

// Define async thunks for each operation
export const createOrdersSlice = AsyncFunctionThunk('createOrders', createOrders);

// Create the slice for course modules
const OrderSlice = createSlice({
	name: 'Orderslice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// createOrdersSlice
			.addCase(createOrdersSlice.fulfilled, (state, action) => {
				state.order = action.payload;
				state.loading = false;
			})
			.addCase(createOrdersSlice.pending, (state) => {
				state.loading = true;
			})
			.addCase(createOrdersSlice.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});
	},
});

// Export actions and reducers
export default OrderSlice.reducer;
